import React from 'react';

import styles from './styles.module.css';

const SubregionDescription = ({ title, description }) => {
  return (
    <>
      <h1 className={styles.subregionTextBoxHeader}>{title + ' Apartments'}</h1>
      <p className={styles.subregionTextBoxDescription}>{description}</p>
    </>
  );
}

export default SubregionDescription;
