import React from 'react';
import get from 'lodash/get';
import BackgroundImageHandler from '../../helpers/components-data-handlers/background-image-handler';
import MultiLineString from '../../helpers/mutli-line-string';
import styles from './styles.module.css';

export default (props) => {
  const subtitle = get(props, 'data.primary.subtitle_1');
  const textBlurb = MultiLineString(get(props, 'data.primary.text_blurb_1'));
  const image = BackgroundImageHandler(get(props, 'data.primary.image_1'));

  return (
    <span className={styles.collageWrapper}>
      <div className={styles.blurbContainer}>
        <h2 className={styles.blurbTitle}>{subtitle}</h2>
        <p className={styles.blurbText}>{textBlurb}</p>
      </div>
      <div className={styles.imageContainer} {...image} />
    </span>
  );
};
