import React from 'react';
import get from 'lodash/get';
import { withRouter } from 'react-router';
import FooterView from '../../components/FooterView';
import CaliforniaPrivacyComponent from '../CaliforniaPrivacyComponent';
import instagram from '../../assets/images/instagram-footer.png';
import pinterest from '../../assets/images/pinterest.svg';
import glassdoor from '../../assets/images/gd-white.png';
import styles from './styles.module.css';
import './index.css';

export default withRouter(props => {
  const links = get(props, 'links') || [];
  const year = new Date().getFullYear();

  const region = get(props, 'data.data.regionTag');
  const businessID = get(props, 'data.data.business_id[0].text');

  const onCareersPage = props.location.pathname.includes('/careers');

  const onDenverPage = region === 'Denver';
  const propertyName = get(props, 'data.uid');
  const onOverviewPage = props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1) === propertyName;
  const displayLegalDisclaimer = onOverviewPage && onDenverPage;

  return (
    <FooterView>
      <logo tabIndex={0} onClick={() => props.history.push('/')} role="button" className={styles.amliLogo} alt="AMLI logo" />
      <social-media-logos>
        <a href="https://www.instagram.com/amliapts/" className={styles.socialContainer}>
          <img src={instagram} alt="instagram logo" className={`af-class-social-icon ${styles.socialLogo}`} />
        </a>
        <a href="https://www.pinterest.com/amliresidential/" className={styles.socialContainer}>
          <img src={pinterest} alt="pinterest logo" className={`af-class-social-icon ${styles.socialLogo}`} />
        </a>
        {onCareersPage && (
          <a href="https://www.glassdoor.com/Overview/Working-at-AMLI-Residential-EI_IE2514.11,27.htm" className={styles.socialContainer}>
            <img src={glassdoor} alt="glassdoor logo" className={`af-class-social-icon ${styles.socialLogo}`} />
          </a>
        )}
      </social-media-logos>
      <link-list>
        <a href="https://investor.amli.com" rel="noopener noreferrer" target="_blank" className="af-class-footer-text-link">
          Investor Portal
        </a>
        <a href="http://amlivendors.com" rel="noopener noreferrer" target="_blank" className="af-class-footer-text-link">
          Vendors
        </a>
        {links.map(link => (
          <a
            href={`/${link.uid}`}
            onClick={e => {
              if (e && e.preventDefault) {
                e.preventDefault();
              }
              props.history.push(`/${link.uid}`);
            }}
            key={`footerLink_${link.uid}`}
            className="af-class-footer-text-link"
          >
            {link.data.name}
          </a>
        ))}
        <a href="https://www.bcbsil.com/asomrf?EIN=363207100" rel="noopener noreferrer" target="_blank" className="af-class-footer-text-link">
          Transparency In Coverage Information
        </a>
      </link-list>
      <copyright-text className={styles.legalLinks}>{`${year} © AMLI Copyright. All Rights Reserved. `}</copyright-text>
      {businessID && <business-notification className={styles.legalLinks}>{`${region} Business License: ${businessID}`}</business-notification>}
      <equal-housing-logo alt="Equal Housing logo" />
      <terms-link
        className={styles.legalLinks}
        href="/terms-of-use"
        onClick={e => {
          if (e && e.preventDefault) {
            e.preventDefault();
          }
          props.history.push('/terms-of-use');
        }}
      />
      <privacy
        className={styles.legalLinks}
        href="/privacy-policy"
        onClick={e => {
          if (e && e.preventDefault) {
            e.preventDefault();
          }
          props.history.push('/privacy-policy');
        }}
      />
      <cookie-settings className={styles.legalLinks}>
        <CaliforniaPrivacyComponent />
      </cookie-settings>
      {displayLegalDisclaimer && (
        <legal-disclaimer>
          <p>
            Applicant has the right to provide AMLI Management Company with a Portable Tenant Screening Report (PTSR) that is not more than 30 days
            old, as defined in § 38-12-902(2.5), Colorado Revised Statutes; and 2) if Applicant provides AMLI Management Company with a PTSR, AMLI
            Management Company is prohibited from: a) charging Applicant a rental application fee; or b) charging Applicant a fee AMLI Management
            Company to access or use the PTSR.
          </p>
        </legal-disclaimer>
      )}
    </FooterView>
  );
});
