import axios from "axios";
import client from "./apollo-client";
import { getAllSwitchCodesQuery } from "../graphql/queries/getAllSwitchCodesQuery";
import { entrataPhoneQuery } from "../graphql/queries/entrataPhoneQuery";

async function getDniPhone(entrataId, switchCode, ref)
{
    let swcode = switchCode;

    if (!switchCode && ref)
    {
        try
        {
            const response = await client.query({
                query: getAllSwitchCodesQuery,
            });

            const codes = response.data.getAllSwitchCodes;

            for (let code of codes)
            {
                const regex = new RegExp(code.provider, "i");
                if (!!ref.match(regex)) {
                    swcode = code.code;
                }
            }
        }
        catch (e)
        {
            //pass
        }
    }

    const data = await client.query({
        query: entrataPhoneQuery,
        variables: {
            entrataId: entrataId ? entrataId.toString() : "",
            switchCode: swcode ? swcode.toString() : "",
            referralSite: ref ? ref.toString() : ""
        },
        fetchPolicy: 'network-only'
    });

    const result = data &&
        data.data.entrataPhone &&
        data.data.entrataPhone.phone
        ? data.data.entrataPhone.phone
        : undefined;

    return result;
}

export default getDniPhone;
