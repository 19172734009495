import React, { useState } from 'react';
import get from 'lodash/get';

import { handlePrismicLink } from '../../helpers/link-helper';
import BackgroundImageHandler from './../../helpers/components-data-handlers/background-image-handler';

import styles from './styles.module.css';

export default (props) => {
  const { data } = props;
  const pageBody = get(props, 'page.data.body', []);
  const extraSlidesContent = pageBody
    .filter(
      (item) =>
        item.slice_type === 'card_with_fact' ||
        item.slice_type === 'content_collage_point_of_interest'
    )
    .map((item) => item.primary);
  const [contentCollagePOI, cardWithFact] = [...extraSlidesContent];

  const sliderData = [
    {
      image: BackgroundImageHandler(get(data, 'primary.point_image_1')),
      alt: get(data, 'primary.point_image_alt_text_1'),
      tag: get(data, 'primary.point_tag_1'),
      link: handlePrismicLink(props, get(data, 'primary.point_fact_link_1')),
      blurb: get(data, 'primary.point_blurb_1') ? get(data, 'primary.point_blurb_1')[0] : {},
    },
    {
      image: BackgroundImageHandler(get(data, 'primary.point_image_2')),
      alt: get(data, 'primary.point_image_alt_text_2'),
      tag: get(data, 'primary.point_tag_2'),
      link: handlePrismicLink(props, get(data, 'primary.point_fact_link_2')),
      blurb: get(data, 'primary.point_blurb_2') ? get(data, 'primary.point_blurb_2')[0] : {},
    },
    {
      image: BackgroundImageHandler(get(data, 'primary.point_image_3')),
      alt: get(data, 'primary.point_image_alt_text_3'),
      tag: get(data, 'primary.point_tag_3'),
      link: handlePrismicLink(props, get(data, 'primary.point_fact_link_3')),
      blurb: get(data, 'primary.point_blurb_3') ? get(data, 'primary.point_blurb_3')[0] : {},
    },
    {
      image: BackgroundImageHandler(get(contentCollagePOI, 'image_2')),
      alt: get(contentCollagePOI, 'image_alt_text_2'),
      tag: get(contentCollagePOI, 'subtitle_2'),
      link: {},
      blurb: {
        text: get(contentCollagePOI, 'text_blurb_2'),
      },
    },
    {
      image: BackgroundImageHandler(get(cardWithFact, 'image')),
      alt: get(cardWithFact, 'image.alt'),
      tag: get(cardWithFact, 'subtitle'),
      link: {},
      blurb: {
        text: get(cardWithFact, 'text_blurb'),
      },
    },
  ];

  const subregionName = get(props, 'page.data.name');

  // State and handlers
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideIncrement = () => {
    if (currentSlide === sliderData.length - 1) {
      return setCurrentSlide(0);
    }
    setCurrentSlide(currentSlide + 1);
  };

  const handleSlideDecrement = () => {
    if (currentSlide === 0) {
      return setCurrentSlide(sliderData.length - 1);
    }
    setCurrentSlide(currentSlide - 1);
  };

  const handleIndicatorClick = (e) => {
    e.preventDefault();
    const targetSlide = Number(e.target.getAttribute('slide-indicator'));
    setCurrentSlide(targetSlide);
  };

  const slideState = {
    transform: `translateX(calc(-${100 * currentSlide}% - ${8 * currentSlide}em))`,
  };

  // touch controls
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) return handleSlideIncrement();
    if (isRightSwipe) return handleSlideDecrement();
  };

  sliderData.forEach((slider, index) => {
    sliderData[index].image.style.backgroundPosition = 'center bottom';
  })

  return (
    <div className={styles.sliderContainer}>
      <h2 className={styles.sliderTitle}>
        Things to do{' '}
        <span className={styles.sliderTitleSubtext}>{`${subregionName} Neighborhood`}</span>
      </h2>
      <div
        className={styles.sliderContent}
        style={slideState}
        {...{ onTouchStart, onTouchMove, onTouchEnd }}
      >
        {sliderData.map(({ image, alt, tag, link, blurb }, index) => {
          return (
            <div className={styles.slide} key={`slide-${index}`}>
              <a className={styles.slideBackground} href={link.href} {...image}>
                {alt !== '' && alt !== '""' ? <img alt={alt} /> : null}
              </a>
              <div className={styles.slideBlurbContainer}>
                <a href={link.href}>
                  <h3 className={styles.slideBlurbTitle}>{tag}</h3>
                </a>
                <p className={styles.slideBlurb}>{blurb.text}</p>
              </div>
              <div
                className={[styles.prevSlide, styles.slideChange].join(' ')}
                onClick={handleSlideDecrement}
              >
                <div className={[styles.prevSlideArrow, styles.slideArrow].join(' ')} />
              </div>
              <div
                className={[styles.nextSlide, styles.slideChange].join(' ')}
                onClick={handleSlideIncrement}
              >
                <div className={[styles.nextSlideArrow, styles.slideArrow].join(' ')} />
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.sliderIndicatorContainer}>
        {sliderData.map((slide, index) => {
          return (
            <div
              className={styles.sliderIndicator}
              iscurrentslide={String(currentSlide === index)}
              slide-indicator={index}
              onClick={handleIndicatorClick}
              key={`slide-indicator-${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};
