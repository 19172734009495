import React, { useEffect, useState, useRef  } from "react";
import { withRouter } from "react-router";
import ReactDOM from 'react-dom';
import get from "lodash/get";
import DatePicker from "react-date-picker";
import FloorplansConfigurationFilterView from "../../components/FloorplansConfigurationFilterView";
import FloorplanConfigurationOptionView from "../../components/FloorplanConfigurationOptionView";
import queryString from "query-string";
import AdditionalFilters from "./additionalFilters";
import BathroomFilters from "./bathroomFilters";
import BedroomFilters from "./bedroomFilters";

import "./index.css";
import styles from "./styles.module.css";
import { personaColorStyle } from "../../helpers/property/persona";
import { EventSearchFloorPlan } from "../../helpers/plugins/guestAnalytics";
import { canUseDOM } from "../../helpers/utils";

//PBI 752 - Fee Transparency
/*import { PDFReader  } from 'react-read-pdf';*/

const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const beds = {
  0: "Studio",
  1: "1 Bedrooms",
  2: "2 Bedrooms",
  3: "3 Bedrooms",
  4: "4 Bedrooms",
  5: "5 Bedrooms",
};

let baths = [];

baths.push({
  label: "1 Baths",
  value: [1],
});

baths.push({
  label: "1.5 Baths",
  value: [1.5],
});

baths.push({
  label: "2 Baths",
  value: [2],
});

baths.push({
  label: "2.5 Baths",
  value: [2.5],
});

baths.push({
  label: "3 Baths",
  value: [3],
});

baths.push({
  label: "3.5 Baths",
  value: [6],
});

baths.push({
  label: "4 Baths",
  value: [7],
});

const filteredValues = {
  dropDownID: "",
  dropDownValues: [],
};

const moment = require("moment");

function FloorplansConfigurationFilter(props) 
{
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [localFilters, setLocalFilters] = useState(props.filters || {});

  const [pdfUrl, setPdfUrl] = useState('');
  const [showMobilePDFVersion, setShowMobilePDFVersion] = useState(false);

  useEffect(() => {
    const options = Object.keys(beds)
      .map((key) =>
        +key >= attributes.minBeds && +key <= attributes.maxBeds
          ? {
              value: +key,
              label: beds[key],
            }
          : null
      )
      .filter((opt) => !!opt);
    if (!!options && options.length) {
      setLocalFilters({ ...localFilters, size: options[0].value });
      props.setFilters({
        ...props.filters,
        ...localFilters,
        size: options[0].value,
      });
    }

    if (!!props.history.location.search) {
      const { size } = queryString.parse(props.history.location.search);
      if (!!size) {
        setLocalFilters({ ...localFilters, size: +size });
        props.setFilters({
          ...props.filters,
          ...localFilters,
          size: +size,
          showAll: false,
        });
      }
    }
  }, [props.attributesQuery.propertyAttributes]);

  const attributes = get(props, "attributesQuery.propertyAttributes") || {};
  const loading = get(props, "attributesQuery.loading");

  if (loading) {
    return null;
  }

  const engrainId = get(props, "property.data.engrain_id");
  const sightmapBaseURL = 'https://sightmap.com/embed/';
  const sitePlan = (engrainId) ? sightmapBaseURL + engrainId : null;
  
  const selectedDate = localFilters.date;

  const setFilters = (newFilters) => {
    // if (window && window.innerWidth < 992) {
    props.setFilters({ ...props.filters, ...newFilters });
    // }
    setLocalFilters({ ...localFilters, ...newFilters });
  };
  const options = Object.keys(beds)
    .map((key) =>
      +key >= attributes.minBeds && +key <= attributes.maxBeds
        ? {
            value: +key,
            label: beds[key],
          }
        : null
    )
    .filter((opt) => !!opt);
  const { showAll } = props.filters;

  const primaryColor = get(props, "property.data.primary_color");

  if (canUseDOM) {
    const { width } = document.body.getBoundingClientRect();
    const inputs = document.querySelectorAll(".react-date-picker input") || [];
    if (inputs && width < 480) {
      inputs.forEach((item) => item.setAttribute("readonly", "readonly"));
      inputs.forEach((item) => item.setAttribute("disabled", true));
    }
  }

 //#region Fee Transparency

  //PBI 752

  let pdfDocumentURL = "";

  let buttonPosition = {};

//STEP 1: STORE region details
const regionTag = get(props, "property.data.regionTag");  
   
//STEP 2: DETERMINE if socal
if (regionTag && regionTag.toUpperCase() === "SOUTHERN CALIFORNIA")
{
    const property = get(props, "property.data.name") || "";

    //STEP 3: STORE property pdf  
    switch (property.toUpperCase())
    {
        case "AMLI PARK BROADWAY":
            pdfDocumentURL = "https://amli-website.cdn.prismic.io/amli-website/Zo7DZh5LeNNTxAMf_1-Park_Broadway_Fee_Sheet_0724.pdf";
             
            break;
           
        case "AMLI LEX ON ORANGE":
            pdfDocumentURL = "https://amli-website.cdn.prismic.io/amli-website/Zo7Dlx5LeNNTxAM1_2-Lex_on_Orange_Fee_Sheet_0724.pdf";
             
            break;

        case "AMLI UPTOWN ORANGE":
            pdfDocumentURL = "https://amli-website.cdn.prismic.io/amli-website/Zo7DuB5LeNNTxAM8_3-Uptown_Orange_Fee_Sheet_0724.pdf";
             
            break;

        case "AMLI MARINA DEL REY":
            pdfDocumentURL = "https://amli-website.cdn.prismic.io/amli-website/Zo7D2R5LeNNTxAND_4-MDR_Fee_Sheet_0724.pdf";
             
            break;

        case "AMLI WARNER CENTER":
            pdfDocumentURL = "https://amli-website.cdn.prismic.io/amli-website/Zo7D-x5LeNNTxANK_5-Warner_Center_Fee_Sheet_0724.pdf";
             
            break;

        case "AMLI SPANISH HILLS":
            pdfDocumentURL = "https://amli-website.cdn.prismic.io/amli-website/Zo7EHR5LeNNTxANP_6-Spanish_Hills_Fee_Sheet_0724.pdf";
             
            break;

        case "AMLI OLD PASADENA":
            pdfDocumentURL = "https://amli-website.cdn.prismic.io/amli-website/Zo7EQx5LeNNTxANV_7-Old_Pasadena_Fee_Sheet_0724.pdf";
             
            break;

        case "AMLI AERO":
            pdfDocumentURL = ""; // PROPERTY NOT ACTIVE YET
             
            break;

        default:
            pdfDocumentURL = "";

            break;
    }        
}
    const openPDFWindow = (pdfUrl) => 
    {
        const width = 800;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        const pdfWindow = window.open('', '_blank', `width=${width},height=${height},left=${left},top=${top},scrollbars=no`);
        
        if (pdfWindow) 
        {
          const currentDate = new Date().toLocaleDateString();
          pdfWindow.document.write('<html><head><title>Understanding Cost</title><style>body { margin: 0; overflow: hidden; }</style></head><body>');
          pdfWindow.document.write('<div style="display: flex; justify-content: space-between; align-items: center; padding: 10px;">');
          pdfWindow.document.write(`<span>${currentDate}</span>`);
          pdfWindow.document.write('</div>');
          pdfWindow.document.write('<div id="pdfContainer" style="width: 100%; height: calc(100vh - 50px);"></div>');
          pdfWindow.document.close();

          pdfWindow.onload = () => {
            const container = pdfWindow.document.getElementById('pdfContainer');
            const embed = pdfWindow.document.createElement('embed');
            embed.src = pdfUrl;
            embed.type = 'application/pdf';
            embed.style.width = '100%';
            embed.style.height = '100%';
            container.appendChild(embed);
          };
        }
    };     
    
    const handleFeeTransparencyClick = (pdfDoc) => 
    {
        if (pdfDoc) 
        {
            if (isMobile()) 
            {
            const buttonElement = document.getElementById('floorplans-understanding-costs');
        
            if (buttonElement) 
            {
                const { top, left } = buttonElement.getBoundingClientRect();
                buttonPosition = { top: top + window.scrollY, left };
            }

                setPdfUrl(pdfDoc);
                setShowMobilePDFVersion(true);
            } 
            else 
            {
                openPDFWindow(pdfDoc);
            }
        }
    };

 //#endregion

  return (
    <FloorplansConfigurationFilterView>
      <configuration-list> </configuration-list>
      <move-in-wrapper
        topelement={() => (
          <AdditionalFilters {...props} filteredValues={filteredValues} />
        )}
        bedroomfilters={() => (
          <BedroomFilters
            {...props}
            beds={beds}
            setFilters={setFilters}
            filteredValues={filteredValues}
          />
        )}
        bathroomfilters={() => (
          <BathroomFilters
            {...props}
            baths={baths}
            setFilters={setFilters}
            filteredValues={filteredValues}
          />
        )}
      >
        <move-in
          id="property-floorplans-datepicker"
          className={styles.moveIn}
          tabIndex={0}
          aria-label="Date Picker"
          onClick={(e) => {
            e.preventDefault();
            if (e.stopPropagation()) {
              e.stopPropagation();
            }
            if (e.target && e.target && e.target.type === "button") {
              setDatePickerOpen(true);
            } else {
              setDatePickerOpen(!datePickerOpen);
            }
          }}
          onKeyDown={(e) => {
            if (canUseDOM && e.keyCode === 13 && !datePickerOpen) {
              setDatePickerOpen(true);
              document.querySelector('input[name="month"]').focus();
            } else if (e.keyCode === 13 && datePickerOpen) {
              setDatePickerOpen(false);
            }
            if (e.keyCode === 27) {
              setDatePickerOpen(false);
            } else if (canUseDOM && !datePickerOpen && e.keyCode === 9) {
              e.preventDefault();
              document.getElementById("floorplans-understanding-costs").focus();
              setDatePickerOpen(false);
            }
          }}
        >
          <move-in-date>
            <DatePicker
              calendarType="US"
              isOpen={datePickerOpen}
              minDate={new Date()}
              maxDate={moment()
                .add(120, "days")
                .toDate()}
              className={styles.datepicker}
              calendarIcon={null}
              clearIcon={null}
              onKeyDown={(e) => {
                if (e.keyCode === 27) {
                  setDatePickerOpen(false);
                  
                } 
                if (canUseDOM && !datePickerOpen && e.keyCode === 9 && sitePlan) {
                  e.preventDefault();
                  document.getElementById('floorplans-site-plan-button').focus();
                  setDatePickerOpen(false);
                } else if (canUseDOM && datePickerOpen && e.keyCode === 9) {
                  e.preventDefault();
                  document
                    .getElementById("floorplans-understanding-costs")
                    .focus();
                  setDatePickerOpen(false);
                }
              }}
              onChange={(date) => {
                setFilters({ date });
                setDatePickerOpen(false);
                const { property, filters } = props;
                EventSearchFloorPlan(
                  property.data.amliPropertyId,
                  date,
                  filters.size
                );
              }}
              value={selectedDate}
            />
          </move-in-date>
        </move-in>
        <fees-link
          id="floorplans-understanding-costs"
          className={`${styles.feesLink} property-page-fees-link-for-hover`}
          href="#understanding-fees"
          onClick={(e) => 
          {
            if (pdfDocumentURL)
            {
                e.preventDefault();
                handleFeeTransparencyClick(pdfDocumentURL);
            }
            else
            {
                if (e) 
                {
                  e.preventDefault();
                }
                if (document.querySelector(".af-class-policies-tabs")) {
                  document.querySelector(".af-class-policies-tabs").scrollIntoView({
                    behavior: "smooth",
                  });
                  // commented code below breaks the smooth behavior
                  // document
                  //   .querySelector(".af-class-policies-tabs .af-class-active")
                  //   .focus();
                }
             }
          }}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99996 18.3333C14.6023 18.3333 18.3333 14.6023 18.3333 9.99996C18.3333 5.39759 14.6023 1.66663 9.99996 1.66663C5.39759 1.66663 1.66663 5.39759 1.66663 9.99996C1.66663 14.6023 5.39759 18.3333 9.99996 18.3333Z" stroke="#7BA4DA" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 13.3333V10" stroke="#7BA4DA" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 6.66663H10.0131" stroke="#7BA4DA" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          Understanding Costs
        </fees-link>
        {
          props.furnishedAvailable ?
            <furnished-link
              id="floorplans-corporate-furnished"
              className={`${styles.feesLink} property-page-fees-link-for-hover`}
              href="#short-term-furnished"
              onClick={(e) => {
                if (e) {
                  e.preventDefault();
                }
                if (document.querySelector("#short-term-furnished")) {
                  document.querySelector("#short-term-furnished").scrollIntoView({
                    behavior: "smooth",
                  });
                }
              }}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.99996 18.3333C14.6023 18.3333 18.3333 14.6023 18.3333 9.99996C18.3333 5.39759 14.6023 1.66663 9.99996 1.66663C5.39759 1.66663 1.66663 5.39759 1.66663 9.99996C1.66663 14.6023 5.39759 18.3333 9.99996 18.3333Z" stroke="#7BA4DA" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10 13.3333V10" stroke="#7BA4DA" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10 6.66663H10.0131" stroke="#7BA4DA" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              Corporate Furnished

            </furnished-link>
            :
            null
        }
      </move-in-wrapper>
    <mobile-understanding-cost>
        {showMobilePDFVersion && (
        <div style={{
            position: 'absolute',
            top: buttonPosition.top,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        }}>
            <button onClick={() => setShowMobilePDFVersion(false)}>Close</button>
            <div style={{ width: '100%', height: 'calc(100% - 50px)' }}>
            <span style={{ backgroundColor: 'white' }}>{new Date().toLocaleDateString()}</span>  
            <iframe src={pdfUrl} style={{ width: '100%', height: '100%', border: 'none' }} />
            </div>
        </div>
        )}        
    </mobile-understanding-cost>
      
    </FloorplansConfigurationFilterView>
  );
}

export default withRouter(FloorplansConfigurationFilter);
