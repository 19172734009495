/* eslint-disable */

import React from 'react';
import { createScope, map, transformProxies } from '../helpers';
import get from 'lodash/get';

import RegionHeaderSliderView from './RegionHeaderSliderView';
import RegionMapView from './RegionMapView';
import SubregionDescription from '../controllers/SubregionDescription';

let Controller;

class RegionHeaderMapView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require('../controllers/RegionHeaderMapController');
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = RegionHeaderMapView;

        return Controller;
      }

      throw e;
    }
  }

  render() {
    const proxies = Controller !== RegionHeaderMapView ? transformProxies(this.props.children) : {};
    const regionUid = get(this.props, 'data.region.uid');
    const regionName = regionUid ? regionUid.replace(/-/g, ' ') : null;
    const regionType = get(this.props, 'page.type');

    const subregionName = get(this.props, 'data.name');

    const headerCrumbsStyles = {
      textTransform: 'capitalize',
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#3f3f3f',
      color: '#fff',
      paddingLeft: '20px',
      fontSize: '20px',
      fontFamily: "'Outfit', sans-serif"
    };

    return (
      <React.Fragment>
        {regionType === 'sub-region' ? (
          <div className="subregionMapHeader" style={headerCrumbsStyles}>
            {window.matchMedia('max-width: 720px').matches ? (
              <SubregionDescription {...{ title, description }} />
            ) : (
              <>
                <a href="/" style={{ textDecoration: 'none', color: '#fff' }}>
                  Amli Home
                </a>
                <span style={{ margin: '0 5px' }}> / </span>
                <a href={`/apartments/${regionUid}`} style={{ textDecoration: 'none', color: '#fff' }}>
                  {regionName}
                </a>
                <span style={{ margin: '0 5px' }}> / </span>
                <span style={{ fontWeight: 'bold' }}>{subregionName}</span>
              </>
            )}
          </div>
        ) : null}
        <span className="af-view" style={{ width: '100%', height: '100%', flex: 1 }}>
          <header className="af-class-header-property-map">
            {regionType === 'region' ? (
              <div className="af-class-real-estate-column">
                <RegionHeaderSliderView.Controller {...this.props} />
                <div className="w-embed">
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        '\n@media (min-width: 992px) and (max-width: 1115px) {\n   .af-class-property-card.af-class-icon-wrapper {\n    display: none;\n  }\n   .af-class-property-card-cta-label {\n    font-size: 16px;\n  }\n   .af-class-property-card-phone, .af-view .af-class-af-view .af-class-property-card-address {\n    font-size: 14px;\n  }\n   .af-class-property-card-info-wtrapper {\n    padding-bottom: 12px;\n    padding-left: 10px;\n  }\n   .af-class-property-card-left-border {\n    width: 0px;\n  }\n   .af-class-property-card-title-wrapper {\n    padding: 15px 9px;\n  }\n   .af-class-toggle-inactive-label, .af-view .af-class-af-view .af-class-toggle-active-label {\n    font-size: 16px;\n  }\n   .af-class-toggle-inactive {\n    padding: 5px 15px 5px 12px;\n  }\n   .af-class-toggle-active {\n    padding: 5px 0px 5px 12px;\n  }\n   .af-class-div-block-34 {\n    padding: 5px 10px;\n  }\n   .af-class-toggle-container {\n    height: 48px;\n    margin-bottom: 9px;\n  }\n   .af-class-real-estate-column-container {\n    padding-top: 9px;\n  }\n}\n'
                    }}
                  />
                </div>
              </div>
            ) : null}
            <RegionMapView.Controller {...this.props} />
          </header>
        </span>
      </React.Fragment>
    );
  }
}

export default RegionHeaderMapView;

/* eslint-enable */
